import React, { FC, useEffect, useState, useRef } from "react";
import Slick from "react-slick";
import "./showTimeFilter.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  dateToFromNowDaily,
  formatConsolidatedCinemas,
  formatLocation,
} from "../../../../utils/formatter";
import { IDropDown } from "../../../../models/common";
import moment from "moment";
import { setNowShowingLoader, setQuickFilter } from "../../../../store/films/action";
import {
  COUNTRY,
  LOCATION_MAPPER,
  SLICK_SETTING,
  SLICK_SETTING_WHEN,
} from "../../../../constants/app";
import {
  COUNTRY_IMAGE_CONFIG,
} from "../../../../constants/url";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../../../constants/path";

interface IShowTimeFilterProps {
  session: IDropDown[];
  type: IDropDown[];
  fromPage?: string;
}

const ShowTimeFilter: FC<IShowTimeFilterProps> = ({
  session,
  type,
  fromPage,
}) => {
  const {
    quickFilters,
    cinemas,
    currentCinema,
    reinitializeFilter,
    isLoggedIn,
    countryId,
  } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
    quickFilters: state.filmsReducer.quickFilters,
    cinemas: state.applicationReducer.cinemas,
    brandId: state.applicationReducer.brandId,
    nowShowing: state.filmsReducer.nowShowing,
    reinitializeFilter: state.applicationReducer.reinitializeFilter,
    isLoggedIn: state.authReducer.isLoggedIn,
    countryId: state.applicationReducer.countryId,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const slideTypeRef = useRef<any>();
  const slideLocationRef = useRef<any>();
  const slideSessionRef = useRef<any>();
  const [cinemaList, setCinemaList] = useState([]);

  useEffect(() => {
    if (cinemas) {
      let format =
        countryId === COUNTRY.CON
          ? formatConsolidatedCinemas(formatLocation(cinemas, true))
          : formatLocation(cinemas, true);
      if (currentCinema && currentCinema.slug) {
        if (countryId === COUNTRY.CON) {
          const tempLocation = format.filter((v: any) =>
            v.find((c: any) => c.value === currentCinema.slug)
          );
          setCinemaList(tempLocation[0]);
        } else {
          const neighbourLocation =
            LOCATION_MAPPER[countryId][currentCinema?.slug]?.neighboringCity ||
            [];
          format = format.filter(
            (cinema: any) =>
              neighbourLocation.includes(cinema.value) ||
              currentCinema.slug === cinema.value
          );
          setCinemaList(format);
        }
      }
    }
  }, [cinemas, currentCinema]);

  useEffect(() => {
    if (reinitializeFilter) {
      if (quickFilters.location) {
        slideSessionRef.current?.slickGoTo(
          cinemaList.findIndex(
            (v: IDropDown) => v.value === quickFilters.location || 0
          )
        );
      }
      if (quickFilters.format) {
        slideTypeRef.current?.slickGoTo(
          type.findIndex((v) => v.value === quickFilters.format) || 0
        );
      }
      if (quickFilters.date) {
        slideSessionRef.current?.slickGoTo(
          session.findIndex((v) => v.value === moment(quickFilters.date) || 0)
        );
      }
      // dispatch(setReinitializeSlider(false));
    }
  }, [quickFilters, reinitializeFilter]);

  const onChange = (value: any, type: string) => {
    dispatch(setNowShowingLoader(false));
    if (fromPage !== 'movieDetails' && !location.pathname.includes(`/${ROUTES.NOW_PLAYING}`)) {
      navigate(`/${currentCinema?.alias}/${ROUTES.NOW_PLAYING}`)
    }
    switch (type) {
      case "format":
        dispatch(
          setQuickFilter({
            ...quickFilters,
            location: quickFilters.location
              ? quickFilters.location
              : currentCinema.slug,
            format: value,
            movie: null,
            session: null,
            // date: null,
          })
        );
        break;
      case "location":
        dispatch(
          setQuickFilter({
            ...quickFilters,
            format: null,
            location: value,
            movie: null,
            session: null,
            //date: null,
          })
        );
        if(window && window.location && window.location.pathname){
          const path = window.location.pathname.split('/');
          path.splice(1,1,LOCATION_MAPPER[countryId]?.[value]?.alias);
          navigate(path.join('/'));
        }
        break;
      case "session":
        dispatch(
          setQuickFilter({
            ...quickFilters,
            location: quickFilters.location
              ? quickFilters.location
              : currentCinema.slug,
            date: value,
            movie: null,
            session: null,
          })
        );
        break;
    }
  };

  // const SlideWhereCon = () => {
  //   const cinema = cinemaList;
  //   return (
  //     <td className="slide-where-con" colSpan={2}>
  //       <div className="wrapper">
  //         <div className="state">
  //           <h5>WEST OAHU</h5>
  //           <div className="city">
  //             <div>KAPOLEI</div>
  //             <div>OLINO</div>
  //           </div>
  //         </div>
  //         <div className="divider"></div>
  //         <div className="state">
  //           <h4>CENTRAL OAHU</h4>
  //           <div className="city">
  //             <div className="active">MILILANI</div>
  //             <div>PEARLRIDGE</div>
  //           </div>
  //         </div>
  //       </div>
  //     </td>
  //   );
  // };

  return (
    <div className={`showtime-content-table-flex cust-showtime-content-table-flex ${countryId === COUNTRY.CON?'consolidated':countryId === COUNTRY.AFC?'angelika-flim-center':''}`}>
      <div className="table-parent">
        <table>
          <tbody className="table_showtime">
            {/* What */}
            {/* <tr className="mobile-show">
              <td colSpan={2} className="label">
                <div>
                  <div className="filter">
                    <img
                      src={
                        COUNTRY_IMAGE_CONFIG[countryId] + "movie-img-icon.svg"
                      }
                    />
                    <span>WHAT</span>
                  </div>
                </div>
              </td>
            </tr> */}
            <tr>
              <td className="label">
                <div>
                  <div className="filter">
                    <img
                      src={
                        COUNTRY_IMAGE_CONFIG[countryId] + "movie-img-icon.svg"
                      }
                      alt="movie icon"
                    />
                    <div className="filter-title">WHAT</div>
                  </div>
                </div>
              </td>
              <td className="action">
                <div className="wrapper">
                  <div
                    onClick={() => onChange(null, "format")}
                    className={
                      !quickFilters.format
                        ? `common-filter active`
                        : "common-filter"
                    }
                  >
                    <span style={{ minWidth: 100, textAlign: "center" }}>
                      EVERYTHING
                    </span>
                    <div
                      className={!quickFilters.format ? `active` : "non-active"}
                    ></div>
                  </div>
                </div>
              </td>
              <td className="slider">
                <div className="d-flex align-items-center">
                  <Slick {...SLICK_SETTING_WHEN} ref={slideTypeRef}>
                    {type?.map((t: any, index: number) => (
                      <div
                        key={`date${index}`}
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => onChange(t.value, "format")}
                        className={
                          quickFilters.format &&
                          quickFilters.format.includes("signature-")
                            ? quickFilters.format === t.value
                              ? "active"
                              : ""
                            : `${quickFilters.format}`.toLowerCase() ===
                              t.label.toLowerCase()
                            ? "active"
                            : ""
                        }
                      >
                        {t.label.toLowerCase() === "titan luxe" ? (
                          <div className="wrapper top">
                            <span className={`titan-width ${fromPage}`}></span>
                          </div>
                        ) : t.label.toLowerCase() === "titan xc" ? (
                          <div className="wrapper top">
                             <span className={`titanxc-width ${fromPage}`}></span>
                          </div>
                        ) : t.label.toLowerCase() === "imax" || t.label.toLowerCase() === "imax 2d" ? (
                          <div className="wrapper top">
                            <span className={`imax-width ${fromPage}`}></span>
                          </div>
                        ) : t.label.toLowerCase() === "standard" ? (
                          <div className="wrapper">
                            <span className="text">Standard Format</span>
                          </div>
                        ): (
                          <div className="wrapper">
                            <span className="text">{t.label}</span>
                          </div>
                        )}
                        <div
                          className={
                            quickFilters.format &&
                            quickFilters.format.includes("signature-")
                              ? quickFilters.format === t.value
                                ? "active-slider"
                                : ""
                              : `${quickFilters.format}`.toLowerCase() ===
                                t.label.toLowerCase()
                              ? "active-slider"
                              : ""
                          }
                        >
                          {/* &nbsp; */}
                        </div>
                      </div>
                    ))}
                  </Slick>
                </div>
              </td>
              {/* <td className="sessionType mobile-hide">
                {currentCinema?.dolbyAtmosImageUrl?<img className="w-100" src={currentCinema.dolbyAtmosImageUrl} />: null}
              </td> */}
            </tr>
            {/* where */}
            {/* <tr className="mobile-show">
              <td colSpan={2} className="label">
                <div>
                  <div className="filter">
                    <img
                      src={
                        COUNTRY_IMAGE_CONFIG[countryId] +
                        "location-img-icon.svg"
                      }
                    />
                    <span>WHERE</span>
                  </div>
                </div>
              </td>
            </tr> */}
            <tr>
              <td className="label">
                <div>
                  <div className="filter">
                    <img
                      src={
                        COUNTRY_IMAGE_CONFIG[countryId] +
                        "location-img-icon.svg"
                      }
                       alt="location image"
                    />
                    <div className="filter-title">WHERE</div>
                  </div>
                </div>
              </td>
              <td
                className="slider where_list_slider cust-mob-where"
                colSpan={2}
              >
                <div className={`d-flex ${countryId === COUNTRY.CON ? 'sub_category align-items-start' : 'align-items-center'}`}>
                {countryId === COUNTRY.CON ? <div className="category_name">{currentCinema.stateCode}</div>: null}
                  <Slick {...SLICK_SETTING} ref={slideLocationRef}>
                    {cinemaList?.map((cinema: any, index: number) => (
                      <div
                        key={`date${index}`}
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => onChange(cinema.value, "location")}
                        className={
                          currentCinema.slug === cinema.value ? "active" : ""
                        }
                      >
                        <div className="wrapper where-first">
                          <div className="text"> {cinema.displayName}</div>
                        </div>
                        <div
                          className={
                            currentCinema.slug === cinema.value
                              ? "active-slider"
                              : "non-active-slider"
                          }
                        >
                          &nbsp;
                        </div>
                      </div>
                    ))}
                  </Slick>
                </div>
              </td>

              {/* <td className="sessionType mobile-hide"></td> */}
            </tr>
            {/* When */}
            {/* <tr className="mobile-show">
              <td colSpan={2} className="label">
                <div>
                  <div className="filter">
                    <img
                      src={
                        COUNTRY_IMAGE_CONFIG[countryId] +
                        "calendar-img-icon.svg"
                      }
                    />
                    <span>WHEN</span>
                  </div>
                </div>
              </td>
            </tr> */}
            <tr>
              <td className="label">
                <div>
                  <div className="filter">
                    <img
                      src={
                        COUNTRY_IMAGE_CONFIG[countryId] +
                        "calendar-img-icon.svg"
                      }
                      alt="calendar image"
                    />
                    <div className="filter-title">WHEN</div>
                  </div>
                </div>
              </td>
              <td className="action">
                <div className="wrapper">
                  <div
                    onClick={() => onChange(null, "session")}
                    className={
                      !quickFilters.date
                        ? `common-filter active`
                        : "common-filter"
                    }
                  >
                    <span>ANYTIME</span>
                    <div
                      className={!quickFilters.date ? `active` : "non-active"}
                    >
                      {/* &nbsp; */}
                    </div>
                  </div>
                </div>
              </td>
              <td className="slider">
                <div className="d-flex align-items-center">
                  <Slick {...SLICK_SETTING_WHEN} ref={slideSessionRef}>
                    {session?.map((s: any, index: number) => (
                      <div
                        key={`date${index}`}
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => onChange(s.value, "session")}
                        className={
                          quickFilters.date === s.value ? "active" : ""
                        }
                      >
                        <div
                          id="anytime"
                          className="wrapper"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <span>{dateToFromNowDaily(s.value)}</span>
                        </div>
                        <div
                          className={
                            quickFilters.date === s.value
                              ? "active-slider"
                              : "non-active-slider"
                          }
                        >
                          &nbsp;
                        </div>
                      </div>
                    ))}
                  </Slick>
                </div>
              </td>
              {/* <td className="sessionType mobile-hide"></td> */}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShowTimeFilter;
