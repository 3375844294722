import { FC } from "react";
import "./downloadApp.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  COUNTRY_IMAGE_CONFIG,
  IMAGE_URL_CONFIG,
} from "../../../../constants/url";
import { COUNTRY } from "../../../../constants/app";

interface IDownloadAppProps {}

const DownloadOurApp: FC<IDownloadAppProps> = () => {
  const resolution = window.innerWidth;
  const isMobile = resolution >= 320 && resolution <= 480;

  const { settings, countryId } = useSelector((state: RootState) => ({
    settings: state.applicationReducer.settings,
    countryId: state.applicationReducer.countryId,
  }));

  return (
    <section className={`download-app-section ${
      countryId === COUNTRY.CON
        ? "consolidated"
        : countryId === COUNTRY.AFC
        ? "angelika-flim-center"
        : ""
    }`}>
      {countryId?<div className="container-content">
        <h2 className="heading">
          DOWNLOAD OUR{" "}
          {countryId === COUNTRY.CON
            ? "CONSOLIDATED THEATRES"
            : countryId === COUNTRY.AFC
            ? "ANGELIKA FILM CENTER"
            : "READING CINEMAS"}{" "}
          APP
        </h2>
        <div className="wrapper">
          {countryId?<div
            style={{
              backgroundImage: `url(${COUNTRY_IMAGE_CONFIG[countryId]}app_section_desktop.png)`,
            }}
            className="image-content"
            role="img"
            aria-label="image content"
          >
            &nbsp;
          </div>:null}
          <div className="text-wrapper">
            <p className="title" role="heading">The fastest and easiest way to visit.</p>
            <div className="store_icons">
              <a href={settings.playStoreLink} target="_blank">
                <img
                  src={
                    IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "google_play_icon.svg"
                  }
                  alt="google play image"
                  aria-label="open play store in a new tab"
                />
              </a>
              <a href={settings.appleStoreLink} target="_blank">
                <img
                  src={
                    IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "apple_store_icon.svg"
                  }
                  alt="apple store image"
                  aria-label="open apple store in a new tab"
                />
              </a>
            </div>
          </div>
        </div>
      </div>:null}
    </section>
  );
};

export default DownloadOurApp;
