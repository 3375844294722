import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IMovieData, ISessionDetails } from "../../../../models/cinema";
import moment from "moment";
import "./singleShowTime.scss";
import { dateToFromNowDaily, formatFlags, seatFillStatus } from "../../../../utils/formatter";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { enableShowTime } from "../../../../utils/helper";
import {
  COUNTRY_IMAGE_CONFIG,
} from "../../../../constants/url";
import { LOCATION_MAPPER } from "../../../../constants/app";
import humanizeDuration from "humanize-duration";
import SessionAttribute from "../SessionAttribute";
import { setNowShowingLoader, setQuickFilter } from "../../../../store/films/action";
import { openTicket } from "../../../../utils/tickets";
import { clearFoodItemsAction, setTicketLessFb } from "../../../../store/foodAndBeverage/action";
import { getTicketBookingDetails, toggleTicketModal } from "../../../../store/ticketBooking/action";
import { setPopupInfo } from "../../../../store/application/action";
interface ISingleShowTimeProps {
  data: IMovieData;
  tag?: React.ReactNode;
  showDate?: boolean;
  fromPage?: string;
}

const SingleShowTime: FC<ISingleShowTimeProps> = ({ data, tag, showDate, fromPage }) => {
  const { currentCinema, countryId, quickFilters, isLoggedIn } = useSelector((state: RootState) => ({
    currentCinema: state.applicationReducer.currentCinema,
    countryId: state.applicationReducer.countryId,
    quickFilters: state.filmsReducer.quickFilters,
    isLoggedIn: state.authReducer.isLoggedIn
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);

  const openTicketModal = (data: ISessionDetails, movie: IMovieData) => {
    if (data.statusCode === "1") {
      toast.error("Tickets sold out");
      return;
    }
    if (enableShowTime(movie, currentCinema?.timeZone)) {
      if (fromPage === 'signaturePrograming' || fromPage === 'titan') {
        openTicket(
          dispatch,
          setTicketLessFb,
          clearFoodItemsAction,
          toggleTicketModal,
          setPopupInfo,
          getTicketBookingDetails,
          {
            cinemaId: currentCinema.slug,
            sessionId: data.id,
            reservedSeating: "",
            screenType: "",
            countryId: countryId,
            covidFlag: currentCinema.covidFlag,
            isLoggedIn: isLoggedIn,
          }
        );
      } else {
        navigate(
          `/${currentCinema.alias}/sessions/${data.id}/${data.ScheduledFilmId}`
        );
      }
    } else {
      toast.error("Tickets will be on sale soon! Check back later or follow us on social media for announcements!");
    }
  };

  const scrollUp = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop -= 100;
    }
  };
  const scrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop += 100;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setHasScroll(
        Number(scrollRef?.current?.scrollHeight)! > Number(scrollRef?.current?.clientHeight)!
      );
    }, 1);
  }, [data]);


  const showTimeEnable = useMemo(() => {
    return enableShowTime(data, currentCinema?.timeZone)
  }, [data, currentCinema])

  const resetFilter = () => {
    if (fromPage && (fromPage === 'signaturePrograming' || fromPage === 'titan')) {
      dispatch(setNowShowingLoader(false));
      dispatch(setQuickFilter({
        ...quickFilters,
        tempDate: quickFilters.date,
        format: null,
        movie: null,
        session: null,
        date: null,
      }))
    }
  }

  const SessionWrappper = () => {
    return <>
      {data.showdates &&
        data.showdates.map((show: any) => {
          return show.showtypes.map((session: any) => (
            <>
              {show.date && showDate ? (
                <div className="">
                  <a className="show_date">
                    {dateToFromNowDaily(show.date)}
                  </a>
                </div>
              ) : null}
              <div className="movie-show-time-parent-section">
                <div className="movie-show-time-parent">
                  {session.type ? <SessionAttribute sessionType={session.type} fromPage={'singleShow'} /> : null}

                  <div className="movie-show-time-flex">
                    {session.showtimes.map((time: any) => (
                      <div
                        className={`movie-show-time  ${!showTimeEnable ? "show-disable" : ""
                          }`}
                        onClick={() => openTicketModal(time, data)}
                      >
                        <div>
                          {moment
                            .parseZone(time.date_time)
                            .format("hh:mm A")}
                        </div>
                        {time.statusCode === "1" ? (
                          <span className="sold_out_txt">SOLD OUT</span>
                        ) : null}
                        {time.statusCode === "0" ? (
                          <div className="seat_wrap">
                            <div
                              className={`fill_status ${seatFillStatus(
                                time.availableSeats,
                                time.totalNumberOfSeats
                              ) === 0
                                ? "no_fill"
                                : seatFillStatus(
                                  time.availableSeats,
                                  time.totalNumberOfSeats
                                ) === 1
                                  ? "slow_fill"
                                  : seatFillStatus(
                                    time.availableSeats,
                                    time.totalNumberOfSeats
                                  ) === 2
                                    ? "fast_fill"
                                    : ""
                                }`}
                            ></div>

                            {time.statusCode === "0" &&
                              formatFlags(time).map((flagObj: any) => (
                                <div className="flag_wrap">
                                  <span className="flag_text">
                                    {flagObj.short &&
                                      flagObj.short.toLowerCase() ===
                                      "rcc" ? (
                                      <div className={`recliner`}>
                                        &nbsp;
                                      </div>
                                    ) : (
                                      flagObj.short
                                    )}
                                  </span>
                                </div>
                              ))}
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ));
        })}
    </>
  }

  return (
    <>
      <span className="d-block d-lg-none tag-mobile">{tag ? tag : null}</span>
      <div className="movie-details-section-new position-relative">
        <div className="movie-details-content-new">
          <div className="movie-details-left-new">
            <div className="movie-details-img" onClick={resetFilter}>
              <Link
                to={`/${LOCATION_MAPPER[countryId][data.theater].alias
                  }/movies/details/${data.movieSlug}`}
              >
                <img src={data.film_image_original_size} loading="lazy" alt="movie image" />
              </Link>
            </div>
          </div>
          <div className="movie-details-right">
            <span className="d-none d-lg-block">{tag ? tag : null}</span>
            <h3 className={tag ? 'break-word' : ''}><Link
              to={`/${LOCATION_MAPPER[countryId][data.theater].alias
                }/movies/details/${data.movieSlug}`}
            > {data.name}
            </Link></h3>
            <div className="wrap-legends">
              <p className="desc">{data.ratingDescription}</p>
              <p className="rating">
                <span className="vt-line">{data.ratingName} </span>
                {data.length && Number(data?.length) > 0
                  ? humanizeDuration(Number(data.length) * 60 * 1000, {
                    conjunction: " and ",
                  })
                  : ""}
              </p>
              <p className="amenities">{data.amenities}</p>
            </div>
            <div className="showtime-arrow-scroll d-none d-md-block">
              {hasScroll ? (
                <div className="scroll-up scroll" onClick={scrollUp}>
                  <img
                    src={
                      COUNTRY_IMAGE_CONFIG[countryId] + "scroll_arrow_up.svg"
                    }
                    alt="scroll up image"
                    loading="lazy"
                  />
                </div>
              ) : null}
              <div className="session-wrapper" ref={scrollRef}>
                <SessionWrappper />
              </div>
              {hasScroll ? (
                <div className="scroll-down scroll" onClick={scrollDown}>
                  <img
                    src={
                      COUNTRY_IMAGE_CONFIG[countryId] +
                      "scroll_arrow_down.svg"
                    }
                    alt="scroll down image"
                    loading="lazy"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-block d-md-none pb-1">
          <div className="session-wrapper-mobile">
            <SessionWrappper />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleShowTime;
