import { FC, useEffect, useMemo, useState } from "react";
import "./home.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "../../../components/UI/Slider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  getPromoAdWatcher,
  getSlidesWatcher,
} from "../../../store/application/action";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG } from "../../../constants/url";
import ShowTimes from "./ShowTimes";
import ComingSoonSection from "./ComingSoon";
import NewsLetter from "./NewsLetter";
import SignatureProgram from "./SignatureProgram";
import EatAndDrink from "./EatAndDrink";
import SpecialValues from "./SpecialValues";
import DownloadOurApp from "./DownloadApp";
import PlfProgramming from "./PlfProgramming";
import { Outlet, useLocation } from "react-router";
import { BRANDS, COUNTRY } from "../../../constants/app";
import HtmlRender from "../../../components/UI/HtmlRender";
import moment from "moment";
import AngelikaMembership from "./AngelikaMembership";
import HelmetTitle from "../../../components/Helmet";
import { ROUTES } from "../../../constants/path";
import { defaultSchema, schemaMarkup } from "../../../constants/schema";
import { helmetJsonLdProp } from "react-schemaorg";

interface IHomeProps {}

const Home: FC<IHomeProps> = () => {
  const { countryId, slides, currentCinema, promoAd, brandId, homeContent, quickFilters, nowShowingData } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      slides: state.applicationReducer.slides,
      currentCinema: state.applicationReducer.currentCinema,
      promoAd: state.applicationReducer.promoAd,
      brandId: state.applicationReducer.brandId,
      homeContent: state.applicationReducer.homeContent,
      quickFilters: state.filmsReducer.quickFilters,
      nowShowingData: state.filmsReducer.nowShowing
    }));

  const dispatch = useDispatch();
  const location = useLocation();

  const [promoText, setPromoText] = useState<any[]>([]);


  useEffect(() => {
    let tempPromo: any = [];
    if (promoAd && promoAd.length > 0) {
      promoAd &&
        promoAd.map((item: any, index: number) => {
          if (item.postLaterFlag == 1 && item.autoArchiveFlag === 1) {
            if (
              moment() >= moment(item.postDateTime) &&
              moment() <= moment(item.archiveDateTime)
            ) {
              tempPromo.push(item?.description);
            }
          } else if (
            (item.postLaterFlag == 1 &&
              moment() >= moment(item.postDateTime)) ||
            (item.autoArchiveFlag === 1 &&
              moment() <= moment(item.archiveDateTime))
          ) {
            tempPromo.push(item?.description);
          } else if (!item.postLaterFlag && !item.autoArchiveFlag) {
            tempPromo.push(item?.description);
          }
        });
      setPromoText(tempPromo);
    } else {
      setPromoText([]);
    }
  }, [promoAd]);

  useEffect(() => {
    if (countryId && currentCinema) {
      dispatch(
        getSlidesWatcher({
          countryId,
          pageName: API_REQUEST_TYPE.HOME,
          location: currentCinema.slug,
        })
      );
      dispatch(
        getPromoAdWatcher({
          flag: "pencilBanner",
          countryId: currentCinema.slug,
        })
      );
    }
  }, [countryId, currentCinema]);

  const PencilBanner = () =>
    brandId === BRANDS.US ? (
      <div className="pencilbanner">
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ width: "80%" }}
        >
          <img
            className="slider-icon"
            src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}warning-pencil-banner.svg`}
            alt="slider image"
          />

          <div className="slider-subheader">
            {promoText &&
              promoText.map((data) => (
                <HtmlRender {...{ className: "slider-text" }} content={data} />
              ))}
          </div>
        </div>
      </div>
    ) : null;

  const title = useMemo(()=>{
    if(location && location.pathname && currentCinema && currentCinema?.alias ){
      if(location.pathname.includes(`/${currentCinema?.alias}/${ROUTES.NOW_PLAYING}`)){
        return `Movie Showtimes - Films Playing Now`;
      }else if(location.pathname.includes(`/${currentCinema?.alias}/${ROUTES.COMING_SOON}`)){
        return `Films Coming Soon`;
      }else if(location.pathname.includes(`/${currentCinema?.alias}/${ROUTES.ADVANCE_TICKETS}`)){
        return `Advance Tickets`;
      }else if(countryId === COUNTRY.AFC){
        return `Movie Theater for Indie & Foreign Films in ${currentCinema?.city} City`
      }else{
        return `Movie Information | Tickets | Showtimes`
      }
    }else{
      return `Movie Information | Tickets | Showtimes`
    }
  },[location,currentCinema])  

  const schema = useMemo(()=>{ 
    if(countryId && currentCinema && schemaMarkup[countryId] && schemaMarkup[countryId][currentCinema.slug]){
      return [
        helmetJsonLdProp<any>(schemaMarkup[countryId][currentCinema.slug]),
      ]
    }else if(countryId && schemaMarkup[countryId]){
      return [
        helmetJsonLdProp<any>(defaultSchema[countryId])
      ]
    }
    return [];
  },[countryId,currentCinema])

  return (
    <div className="home-wrapper">
      <HelmetTitle title={title} description={title} schema={schema}/>
      <div className="slider-filter">
        {promoText.length ? <PencilBanner /> : null}
        <Slider
          className="banner-slider-us"
          data={slides}
          isDotsNeeded={true}
        />
      </div>

      {currentCinema ? (
        <div>
          <ShowTimes />
          {countryId !== COUNTRY.AFC ? <PlfProgramming /> : null}
          <ComingSoonSection />
          {countryId === COUNTRY.AFC ? <AngelikaMembership /> : null}
          <SignatureProgram />
          <EatAndDrink />
          <SpecialValues />
        </div>
      ) : null}

      <DownloadOurApp />
      <NewsLetter></NewsLetter>
      <Outlet />
    </div>
  );
};

export default Home;
