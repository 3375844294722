import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";
import { URLS } from "../../../constants/url";
import "./modal.scss";

interface IPopupProps {
  visible: boolean;
  children: React.ReactNode;
  onClickClose?: () => void;
  showCloseBtn?: boolean;
  closeIconType?: string;
  customRefProp?: React.MutableRefObject<PopupActions>;
  closeOnDocumentClick?: boolean;
}

interface ICustomPopup extends PopupProps, IPopupProps {}

const PopupModal: React.FC<ICustomPopup> = ({
  children,
  visible,
  onClickClose,
  showCloseBtn = true,
  closeIconType = "default",
  customRefProp,
  closeOnDocumentClick = false,
  ...props
}) => {
  const overlayStyle = {
    overflow: "auto",
  };

  const [closeIconImage, setCloseIconImage] = useState("default");

  useEffect(() => {
    switch (closeIconType) {
      case "black":
        setCloseIconImage(URLS.CLOSE_ICON_BLACK);
        break;
      default:
        setCloseIconImage(URLS.CLOSE_ICON);
        break;
    }
  }, [closeIconType]);

  useEffect(() => {
    if (visible) {
      document.body.classList.add('modal-open'); 
    }    
    else if(document.querySelectorAll('.popup-overlay').length <=1) {
      document.body.classList.remove('modal-open'); 
    }
  }, [visible]);

  const CloseBtn = () => {
    return (
      <div>
        <div className="close" onClick={onClickClose}>
          <img className="close_icon" src={closeIconImage} />
        </div>
      </div>
    );
  };

  return (
    <Popup
      className="custom-modal"
      aria-label="popup"
      open={visible}
      modal={true}
      closeOnEscape={false}
      closeOnDocumentClick={closeOnDocumentClick}
      overlayStyle={overlayStyle}
      ref={customRefProp}
      {...props}
    >
      {showCloseBtn ? <CloseBtn /> : null}
      {children}
    </Popup>
  );
};
export default PopupModal;
