import React, { FC, useEffect, useState } from "react";
import "./specialValue.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { specialValueApi } from "../../../../services/films";
import {
  ISignatureProgramming,
  ISpecialValues,
} from "../../../../models/cinema";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { useNavigate } from "react-router";
import { COUNTRY } from "../../../../constants/app";

interface ISpecialValuesProps {}
const SpecialValues: FC<ISpecialValuesProps> = () => {
  const { countryId, currentCinema, homeContent } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      homeContent: state.applicationReducer.homeContent,
    })
  );

  const navigate = useNavigate();

  const [specialValues, setSpecialValues] = useState<ISpecialValues[]>([]);
  
  useEffect(() => {
    if (homeContent.specialValues) {
      setSpecialValues(
        homeContent.specialValues.filter(
          (v: ISpecialValues) => v.ShowOnMovies === 1
        )
      );
    }
  }, [homeContent]);

  const onClickImg = (data: ISpecialValues) => {
    navigate(
      `/${
        currentCinema?.alias
      }/special-values/${data.Title.toLowerCase().replace(/ /g, "_")}`
    );
  };

  return (
    <section
      className={`special-value-section ${
        countryId === COUNTRY.CON
          ? "consolidated"
          : countryId === COUNTRY.AFC
          ? "angelika-film-center"
          : ""
      }`}
    >
      {specialValues.length > 0 ? (
        <div className="special-value-content">
          <h2 className="heading">SPECIAL VALUES & PROGRAMS</h2>
          <div className="d-flex w-100 justify-content-center">
            <div className="container-fluid">
              <div className="card__wrap--outer">
                {specialValues.map((v, index: number) => (
                  <div className="card__wrap--inner">
                    <div className="card">
                      <img
                        onClick={() => onClickImg(v)}
                        src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.image}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`}
                        alt="special value image"
                      />

                      <div className="card__item flexible">
                        {/* <div className="card-heading">
                          <span>{v.Title}</span>
                        </div> */}
                        <div className="card-description">
                          <HtmlRender
                            {...{ className: "description" }}
                            content={v.Description}
                          />
                          <a
                            className="btn-new btn-danger-new"
                            onClick={() => onClickImg(v)}
                            role="button"
                          >
                            LEARN MORE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default SpecialValues;
