import React, { FC, useEffect, useMemo, useState } from "react";
import "./signatureProgram.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { getSignatureProgramsApi } from "../../../../services/films";
import { ISignatureProgramming } from "../../../../models/cinema";
import HtmlRender from "../../../../components/UI/HtmlRender";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/path";
import { COUNTRY } from "../../../../constants/app";

interface ISignatureProgramProps {}
const SignatureProgram: FC<ISignatureProgramProps> = () => {
  const { countryId, currentCinema, homeContent } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    currentCinema: state.applicationReducer.currentCinema,
    homeContent: state.applicationReducer.homeContent
  }));

  const limitLength = 6;

  const [signatureData, setSignatureData] = useState<ISignatureProgramming[]>(
    []
  );
  const navigate = useNavigate();
  const [signature, setSignature] = useState<ISignatureProgramming[]>([]);
  const [limit, setLimit] = useState<number>(limitLength);


  const onShowMore = () => {
    if (signatureData.length != signature.length) {
      setSignature(signatureData.slice(0, limit + limitLength));
      setLimit(limit + limitLength);
    }
  };

  const onShowLess = () => {
    setSignature(signatureData.slice(0, limitLength));
    setLimit(limitLength);
  };

  const showMore = useMemo(() => {
    return (
      signatureData.length > limitLength &&
      signatureData.length != signature.length
    );
  }, [signatureData, signature]);

  const showLess = useMemo(() => {
    return (
      signatureData.length > limitLength &&
      signatureData.length == signature.length
    );
  }, [signatureData, signature]);

  useEffect(() => {
    setSignature(homeContent?.signatureProgramming?homeContent?.signatureProgramming:[]);
  }, [homeContent]);

  const handleImageError = (event: any) => {
    event.target.src = URLS.ERROR_IMAGE_TEXT;
  };

  return (
    <section className={`signatures-series-section ${countryId === COUNTRY.CON?'consolidated':countryId === COUNTRY.AFC?'angelika-film-center':''}`}>
      {signature.length > 0 ? (
        <div className="container-content img-section-parent-two-list img-section-bottom-description">
          <div className="signatures-series-content">
            <h2 className="heading">OUR SIGNATURE PROGRAMMING</h2>
            <div className="signature-wrapper">
              <div className="container-fluid">
                <div className="card__wrap--outer">
                  {signature.map((v, index: number) => (
                    <div className="card__wrap--inner">
                      <div className="card">
                        <img
                          src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.mainImage}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`}
                          onError={handleImageError}
                          onClick={() =>
                            navigate(
                              `/${
                                currentCinema?.alias
                              }/${ROUTES.SIGNATURE_PROGRAM.replace(
                                ":uniqueKey",
                                encodeURIComponent(v.Website_slug)
                              )}`
                            )
                          }
                          alt="signature program image"
                        />
                        <div className="card__item flexible">
                          <HtmlRender content={v.Description} />
                          <a
                            className="btn-new btn-danger-new"
                            role="button"
                            onClick={() =>
                              navigate(
                                `/${
                                  currentCinema?.alias
                                }/${ROUTES.SIGNATURE_PROGRAM.replace(
                                  ":uniqueKey",
                                  encodeURIComponent(v.Website_slug)
                                )}`
                              )
                            }
                          >
                            BUY TICKETS
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {showMore ? (
              <div className="show-more">
                <p onClick={onShowMore}>SHOW MORE</p>
              </div>
            ) : null}
            {showLess ? (
              <div className="show-more">
                <p onClick={onShowLess}>SHOW LESS</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default SignatureProgram;
